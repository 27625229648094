import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Grommet } from 'grommet';

import theme from 'granite/theme';
import PrivateRoute from 'granite/components/PrivateRoute';
import PublicOnlyRoute from 'granite/components/PublicOnlyRoute';
import PublicRoute from 'granite/components/PublicRoute';

import configureStore from 'utils/ducks/configure-store';
import { loadAuthToken } from 'utils/auth-singleton';
import routes from './routes';
//import 'styles/index.global.scss';

// Init Auth Singleton at load
loadAuthToken();

// Create our Redux store.
const store = configureStore();

const App = () => {
  return (
    <ReduxProvider store={store}>
      <Grommet theme={theme} full>
        <Router>
          <Routes>
            {routes.map(route => {
              const { path, component, private: isPrivate, publicOnly, layout, roles } = route;
              if (path === '/') {
              }
              if (isPrivate) {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={<PrivateRoute element={component} layout={layout} roles={roles} />}
                  />
                );
              } else if (publicOnly) {
                return (
                  <Route key={path} path={path} element={<PublicOnlyRoute element={component} layout={layout} />} />
                );
              } else {
                return <Route key={path} path={path} element={<PublicRoute element={component} layout={layout} />} />;
              }
            })}
          </Routes>
        </Router>
      </Grommet>
    </ReduxProvider>
  );
};
export default App;
